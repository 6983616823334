import "./App.css";
import { useReducer, Reducer, useEffect } from "react";
import dayjs from "dayjs";

const start = dayjs("2022-06-12T16:33:00-03:00");

type State = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const App = () => {
  const [state, updateState] = useReducer<Reducer<State, Partial<State>>>(
    (previous, next) => {
      return { ...previous, ...next };
    },
    calcState()
  );

  useEffect(() => {
    const interval = setInterval(() => updateState(calcState()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div id="wrapper">
      <main id="main">
        <h1 id="page-title">Estamos namorando a</h1>

        <div id="period-wrapper">
          <span>
            {zeroToNull(state.years) &&
              `${state.years} ${yearCopy(state.years)}`}
          </span>

          <span>
            {zeroToNull(state.months) &&
              `${state.months} ${monthCopy(state.months)}`}
          </span>

          <span>
            {zeroToNull(state.days) && `${state.days} ${dayCopy(state.days)}`}
          </span>

          <span>
            {zeroToNull(state.hours) &&
              `${state.hours} ${hourCopy(state.hours)}`}
          </span>

          <span>
            {zeroToNull(state.minutes) &&
              `${state.minutes} ${minuteCopy(state.minutes)}`}
          </span>

          <span>
            {zeroToNull(state.seconds) &&
              `${state.seconds} ${secondCopy(state.seconds)}`}
          </span>
        </div>
      </main>

      <span id="footer">Jonathan R. C. & Emilly R. O.</span>
    </div>
  );
};

const zeroToNull = (number: number) => (number == 0 ? null : number);

const yearCopy = (number: number) => (number > 1 ? "anos" : "ano");
const monthCopy = (number: number) => (number > 1 ? "meses" : "mês");
const dayCopy = (number: number) => (number > 1 ? "dias" : "dia");
const hourCopy = (number: number) => (number > 1 ? "horas" : "hora");
const minuteCopy = (number: number) => (number > 1 ? "minutos" : "minuto");
const secondCopy = (number: number) => (number > 1 ? "segundos" : "segundo");

const calcState = (): State => {
  const now = dayjs();
  const years = now.diff(start, "year");

  const startLastYear = start.add(years, "years");
  const months = now.diff(startLastYear, "month");

  const startLastMonth = startLastYear.add(months, "months");
  const days = now.diff(startLastMonth, "day");

  const startLastDay = startLastMonth.add(days, "days");
  const hours = now.diff(startLastDay, "hour");

  const startLastHour = startLastDay.add(hours, "hours");
  const minutes = now.diff(startLastHour, "minute");

  const startLastMinute = startLastHour.add(minutes, "minutes");
  const seconds = now.diff(startLastMinute, "second");

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};
